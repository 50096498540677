<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const loading = ref(false)
const authStore = useAuthStore()
const credentials = reactive({
  username: '',
  password: '',
})

async function handleSignIn() {
  try {
    await authStore.login(credentials)
    useToastification().success(t('login.login_success_description'))
  }
  catch (e) {
    useToastification().error(t('login.login_failed_description'))
  }
}
</script>

<template>
  <div class="absolute left-0 z-0">
    <svg width="236" height="462" viewBox="0 0 236 462" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M70.5561 125.734L47.9264 130.923L71.6481 131.322C73.3859 142.331 72.6671 153.894 69.046 165.23C68.9224 165.617 68.7775 165.991 68.6484 166.374L-7.77531 141.555L64.5703 176.143C56.4805 192.146 43.0289 204.224 27.1853 210.866L-5.27992 154.432L17.5537 214.147C4.3327 217.665 -10.0162 217.587 -24.0243 213.113C-26.7143 212.253 -29.3121 211.243 -31.831 210.115L-13.9113 133.92L50.5769 90.2002C60.4304 99.9646 67.3588 112.308 70.5561 125.734Z" fill="#3E6D72" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-10.7309 69.3966C-4.25837 68.5012 2.38779 68.4646 9.05043 69.3868L5.47624 87.6994L13.5869 70.15C16.1397 70.6706 18.6888 71.3249 21.2264 72.1355C29.9194 74.9123 37.7163 79.192 44.4529 84.5863L-13.6049 134.157L-37.1752 207.946C-42.4789 204.999 -47.3031 201.425 -51.6081 197.366L-10.7309 69.3966Z" fill="#3E6D72" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M100.155 219.005C100.155 219.005 113.102 253.368 153.811 245.063C153.811 245.063 139.316 212.846 100.155 219.005Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M73.1486 201.211C73.1486 201.211 92.1949 212.852 115.449 187.272C115.449 187.272 82.4206 177.075 73.1486 201.211Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M56.4602 193.027C56.4602 193.027 79.8197 205.695 70.8624 231.493C70.8624 231.493 43.7806 213.332 56.4602 193.027Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-34.4704 127.198L-35.0165 127.872C-34.0827 128.627 59.2009 203.746 130.903 234.162L131.243 233.365C59.6536 202.997 -33.5376 127.952 -34.4704 127.198Z" fill="#0B292C" />
      <mask id="mask0_1706_19987" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="97" y="123" width="49" height="47">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M113.576 169.275L145.31 142.712L129.324 123.615L97.5906 150.178L113.576 169.275Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1706_19987)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M97.9663 150.627C97.9663 150.627 117.387 166.754 145.305 142.706C145.305 142.706 120.265 125.367 97.9663 150.627Z" fill="#0B292C" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M74.4477 151.507C74.4477 151.507 92.9599 156.78 98.1304 179.799C98.1304 179.799 71.0547 172.519 74.4477 151.507Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M53.197 150.154C53.197 150.154 75.2353 149.453 84.9487 120.748C84.9487 120.748 55.3721 125.512 53.197 150.154Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0018 143.943C26.0018 143.943 51.9439 154.496 55.6051 179.308C55.6051 179.308 22.574 167.764 26.0018 143.943Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-7.50744 132.08C-7.50744 132.08 12.2204 145.311 30.1469 128.351C30.1469 128.351 -0.685116 114.589 -7.50744 132.08Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-26.7146 120.707L-27.2297 121.414C-27.0655 121.532 -10.5285 133.316 15.3429 142.212C39.2257 150.424 76.1842 157.795 116.631 146.268L116.402 145.431C39.4077 167.374 -26.0618 121.175 -26.7146 120.707Z" fill="#0B292C" />
      <mask id="mask1_1706_19987" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="379" width="95" height="75">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2097 425.794L123.373 453.667L140.032 407.546L62.8692 379.673L46.2097 425.794Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1706_19987)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4777 380.757C62.4777 380.757 54.3323 429.801 123.378 453.652C123.378 453.652 127.974 393.83 62.4777 380.757Z" fill="#0B292C" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4142 341.763C38.4142 341.763 47.8164 378.485 13.7454 410.057C13.7454 410.057 -0.741823 356.777 38.4142 341.763Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8236 304.537C19.8236 304.537 42.7273 341.408 101.104 329.339C101.104 329.339 63.8673 283.669 19.8236 304.537Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.47004 251.937C3.47004 251.937 11.4306 306.454 -27.2859 337.083C-27.2859 337.083 -40.6548 269.529 3.47004 251.937Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-9.65741 183.521C-9.65741 183.521 -12.635 230.049 33.7826 243.687C33.7826 243.687 26.7118 177.806 -9.65741 183.521Z" fill="#0B292C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-10.1132 139.745L-11.8286 139.567C-11.8661 139.961 -15.4655 179.618 -4.87299 232.271C4.90397 280.878 29.1127 350.845 88.9304 408.138L90.1236 406.925C-23.7408 297.863 -10.2609 141.313 -10.1132 139.745Z" fill="#0B292C" />
    </svg>
  </div>

  <div class="flex-center my-12 flex w-full flex-col gap-12 z-10 bg-white">
    <div class="flex w-full flex-col gap-2 rounded-xl border px-8 py-6 text-center">
      <form @submit.prevent="handleSignIn">
        <div class="w-full text-left">
          <h2 class="w-full text-2xl font-bold mb-4">
            {{ t('login.welcome') }}
          </h2>
        </div>

        <div class="min-w-xs flex flex-col gap-6">
          <div class="text-left">
            <label class="text-sm mb-1">
              {{ t('login.email') }} <span class="text-primary">*</span>
            </label>
            <PrimeInputGroup>
              <div class="bg-white border-r-none items-center border !border-r-none rounded-tl-md rounded-bl-md flex justify-center px-3">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.9167 14.9997L12.381 9.99967M7.61913 9.99967L2.08344 14.9997M1.66675 5.83301L8.47085 10.5959C9.02182 10.9816 9.29731 11.1744 9.59697 11.2491C9.86166 11.3151 10.1385 11.3151 10.4032 11.2491C10.7029 11.1744 10.9783 10.9816 11.5293 10.5959L18.3334 5.83301M5.66675 16.6663H14.3334C15.7335 16.6663 16.4336 16.6663 16.9684 16.3939C17.4388 16.1542 17.8212 15.7717 18.0609 15.3013C18.3334 14.7665 18.3334 14.0665 18.3334 12.6663V7.33301C18.3334 5.93288 18.3334 5.23281 18.0609 4.69803C17.8212 4.22763 17.4388 3.84517 16.9684 3.60549C16.4336 3.33301 15.7335 3.33301 14.3334 3.33301H5.66675C4.26662 3.33301 3.56655 3.33301 3.03177 3.60549C2.56137 3.84517 2.17892 4.22763 1.93923 4.69803C1.66675 5.23281 1.66675 5.93288 1.66675 7.33301V12.6663C1.66675 14.0665 1.66675 14.7665 1.93923 15.3013C2.17892 15.7717 2.56137 16.1542 3.03177 16.3939C3.56655 16.6663 4.26662 16.6663 5.66675 16.6663Z" stroke="#22494D" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              <PrimeInputText
                v-model="credentials.username"
                type="email"
                class="border-l-none"
                input-class="w-full !border-l-none"
                :placeholder="t('login.email_placeholder')"
              />
            </PrimeInputGroup>
          </div>
          <div class="text-left">
            <div class="flex justify-between mb-1">
              <label class="text-sm flex-0">
                {{ t('login.password') }}
              </label>
              <NuxtLinkLocale to="/password-reset" class="underline text-primary text-xs">
                {{ t('login.forgot_password') }}
              </NuxtLinkLocale>
            </div>

            <PrimeInputGroup>
              <div class="bg-white border-r-none items-center border !border-r-none rounded-tl-md rounded-bl-md flex justify-center px-3">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 7.5H12.5083M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.28105C5.48487 17.5 5.58679 17.5 5.68269 17.477C5.76772 17.4566 5.84901 17.4229 5.92357 17.3772C6.00767 17.3257 6.07973 17.2536 6.22386 17.1095L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z" stroke="#22494D" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              <PrimePassword
                v-model="credentials.password"
                :feedback="false"
                toggle-mask
                class="border-l-none"
                input-class="w-full !border-l-none"
                :placeholder="t('login.password_placeholder')"
              />
            </PrimeInputGroup>
          </div>

          <div class="flex flex-col gap-3">
            <PrimeButton
              :disabled="!credentials.username || !credentials.password"
              :loading="loading"
              :label="t('login.login')"
              class="w-full"
              type="submit"
            />
            <NuxtLinkLocale to="/register">
              <PrimeButton
                outlined
                class="w-full text-black border-primary"
                :label="t('login.register')"
              />
            </NuxtLinkLocale>
          </div>
          <div class="flex gap-4 items-center text-neutral">
            <div class="bg-neutral/30 w-full h-1px" />
            OF
            <div class="bg-neutral/30 w-full h-1px" />
          </div>

          <NuxtLinkLocale to="/register-as-dealer">
            <PrimeButton
              class="bg-background-dark border-background-dark w-full"
              :label="t('register.as_dealer')"
            />
          </NuxtLinkLocale>
        </div>
      </form>
    </div>
  </div>
</template>
