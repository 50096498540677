<script setup>
const { t } = useI18n()
useHead({
  title: t('login.login'),
})

definePageMeta({
  middleware: ['auth'],
  auth: {
    unAuthenticatedOnly: true,
    navigateAuthenticatedTo: '/account',
  },
})
</script>

<template>
  <div class="flex-center container mx-auto flex max-w-lg flex-col">
    <LoginView />
  </div>
</template>
